import "./Landing.scss";
import Logo from "../../Components/Logo/Logo";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.landing-page-header');
      if (window.scrollY > 0) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <div className="landing-page-gradient-background"></div>
      <div className="landing-page-header-gradient"></div>
      <div className="landing-page-header">
        <div className="landing-page-nav">
          <div className="landing-page-nav-left">
            <span id="company-name">Canditest AI</span>
            <Logo width="30px" height="30px" />
          </div>
          <div className="landing-page-nav-right">
            {/* <button className="plain-button">Features</button>
            <button className="plain-button">Blog</button> */}
            <button className="plain-button" onClick={() => navigate("/auth/signin")}>Log In</button>
            <button className="bold-button-white" onClick={() => navigate("/auth/signup")}>Get Started</button>
          </div>
        </div>
      </div>
      <div className="landing-page-body">
        <div className="landing-page-body-content-block-1">
          <p className="tagline-1">Prove your <span className="highlight-text">skills.</span></p>
          <p className="tagline-2">Get <span className="highlight-text">hired.</span></p>
          <p className="description">Practice coding with an AI interviewer, get skill-assessed, and connect with hiring companies directly.</p>
          <div className="buttons">
            <button className="bold-button-white" onClick={() => navigate("/auth/signup")}>Start Interviewing</button>
            <button className="bold-button-black" onClick={() => window.open('https://calendly.com/gprakarsh/canditest-demo-for-companies', '_blank')}>Hire Candidates</button>
          </div>
        </div>
        <div className="video-container">
          <div className="video-wrapper">
            <video controls>
              <source src={require("../../assets/Canditest AI.mp4")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-image" id="feature-image-1"></div>
            <h3 className="feature-title">Practice Coding Interviews</h3>
            <p className="feature-description">Put your skills to the test with our AI interviewer.</p>
          </div>
          <div className="feature-card">
            <div className="feature-image" id="feature-image-2"></div>
            <h3 className="feature-title">Receive Curated Feedback</h3>
            <p className="feature-description">Get personalized feedback on your skills.</p>
          </div>
          <div className="feature-card">
            <div className="feature-image" id="feature-image-3"></div>
            <h3 className="feature-title">Connect with Hiring Companies</h3>
            <p className="feature-description">Get hired by connecting with top companies.</p>
          </div>
        </div>
      </div>
      <div className="landing-page-footer"></div>
    </div>
  );
};

export default Landing;


/*

<div class="-translate-y-4 md:-translate-y-8 max-w-[95vw] w-[900px] z-20 p-6 md:p-16 md:pb-8 bg-gray-900 rounded-2xl"><div class="relative col justify-center overflow-hidden shadow-lg round-rect w-full h-full" style="filter: drop-shadow(rgba(0, 170, 255, 0.467) 0px 0px 32px);"><video aria-label="A demo of Vivid functionality" controls="" poster="/images/main-demo-thumbnail.png" tabindex="-1"><source src="/images/main-demo.webm" type="video/webm"><source src="/images/main-demo.mp4" type="video/mp4"></video></div></div>

*/