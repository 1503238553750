import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  VStack,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Avatar,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

const Profile = () => {
  const user = useSelector((state) => state.user);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={32}
      px={4}
      maxW="1400px"
      w="100%"
    >
      <Card 
        w="100%" 
        maxW="800px"
        borderWidth="2px" 
        borderRadius="xl" 
        overflow="hidden" 
        bg="#1e1e1e" 
        color="white"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.2)"
        mb={6}
      >
        <CardHeader>
          <VStack spacing={6} align="center">
            <Avatar 
              size="2xl" 
              name={`${user.firstName} ${user.lastName}`} 
              src="" 
              bg="#673FD7"
            />
            <Heading size="lg">
              {user.firstName} {user.lastName}
            </Heading>
          </VStack>
        </CardHeader>
        <CardBody>
          <VStack spacing={4} align="start">
            <Box w="100%">
              <Text color="gray.400" fontSize="sm">Username</Text>
              <Text fontSize="lg">{user.username}</Text>
            </Box>
            <Box w="100%">
              <Text color="gray.400" fontSize="sm">Email</Text>
              <Text fontSize="lg">{user.email}</Text>
            </Box>
          </VStack>
        </CardBody>
      </Card>

      <Alert
        status="info"
        variant="solid"
        bg="#673FD7"
        color="white"
        borderRadius="lg"
        maxW="800px"
        mb={4}
      >
        <AlertIcon color="white" />
        Profile editing functionality coming soon! You'll be able to update your information and customize your profile.
      </Alert>
    </Box>
  );
};

export default Profile; 