import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  useToast,
  List,
  ListItem,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import TopNav from "../../Components/TopNav/TopNav";
import { updateAssessment } from "../../ducks/slices/assessmentSlice";
import useAnalytics from '../../analytics/useAnalytics';
import "./Assessments.scss";
import { ChevronRightIcon } from "@chakra-ui/icons";

const Assessments = () => {
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { logEvent } = useAnalytics();

  useEffect(() => {
    fetchAssessments();
    logEvent('Assessments', 'View Assessments');
  }, []);

  const fetchAssessments = async () => {
    try {
      const response = await axios.get('/info/assessments');
      console.log(response.data);
      setAssessments(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching assessments:", error);
      toast({
        title: "Error",
        description: "Failed to fetch assessments. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleAssessmentClick = (assessment) => {
    console.log(assessment);
    dispatch(updateAssessment({
      overallScore: assessment.overall_score,
      overallFeedback: assessment.overall_feedback,
      categories: assessment.categories,
      strengths: assessment.strengths,
      areasForImprovement: assessment.areas_for_improvement,
      finalRecommendation: assessment.final_recommendation,
      difficulty: assessment.difficulty,
      timeLimit: assessment.time_limit
    }));
    navigate("/assessment");
  };

  const renderContent = () => {
    if (isLoading) {
      return <Text color="white">Loading assessments...</Text>;
    }

    if (assessments.length === 0) {
      return (
        <VStack spacing={4} align="center">
          <Text fontSize="xl" color="white">You don't have any assessments yet.</Text>
          <Button colorScheme="blue" onClick={() => navigate("/interview")}>
            Start an Interview
          </Button>
        </VStack>
      );
    }

    return (
      <List spacing={4} width="100%">
        {assessments.map((assessment) => (
          <ListItem
            key={assessment.assessment_id}
            bg="rgba(255, 255, 255, 0.1)"
            p={4}
            borderRadius="md"
            _hover={{ bg: "rgba(255, 255, 255, 0.2)", transition: "background-color 0.3s" }}
            cursor="pointer"
            onClick={() => handleAssessmentClick(assessment)}
          >
            <Flex align="center">
              <Box>
                <Text color="white" fontWeight="bold" fontSize="lg">
                  {new Date(assessment.created_at).toLocaleDateString(undefined, { 
                    year: 'numeric', 
                    month: 'long', 
                    day: 'numeric' 
                  })}
                </Text>
                <Text 
                  fontSize="2xl" 
                  fontWeight="bold"
                  color={
                    assessment.overall_score <= 3 ? "#ff4d4d" :
                    assessment.overall_score <= 7 ? "#ffd700" :
                    "#4caf50"
                  }
                >
                  Score: {assessment.overall_score}/10
                </Text>
                <Text color="gray.400" fontSize="sm">
                  Difficulty: {assessment.difficulty}
                </Text>
                <Text color="gray.400" fontSize="sm">
                  Time Limit: {assessment.time_limit / 60000} minutes
                </Text>
              </Box>
              <Spacer />
              <Button
                rightIcon={<ChevronRightIcon />}
                color="white"
                bg="#673FD7"
                size="sm"
              >
                View Details
              </Button>
            </Flex>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box minHeight="100vh" width="100%" className="assessments-container">
      <Box maxWidth="1200px" margin="0 auto" padding="6">
        <Heading as="h1" size="xl" mb={6} color="white">
          Your Assessments
        </Heading>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Assessments;
