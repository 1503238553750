import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useAnalytics from "./analytics/useAnalytics";
import SmallScreenView from "./Pages/SmallScreenView/SmallScreenView";
import "./reset.css";
import "./App.scss";

import Assessment from "./Pages/Assessment/Assessment";
import Dashboard from "./Pages/Dashboard/Dashboard";
import LegacyDashboard from "./Pages/LegacyDashboard/LegacyDashboard";
import Assessments from "./Pages/Assessments/Assessments";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import Landing from "./Pages/Landing/Landing";
import SignIn from "./Pages/SignIn/SignIn";
import SignUp from "./Pages/SignUp/SignUp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";

const Interview = lazy(() => import("./Pages/Interview/Interview"));

function App() {
  const isLargeScreen = useMediaQuery({ minWidth: 1100 });
  const location = useLocation();
  const navigate = useNavigate();
  const { logPageView } = useAnalytics();

  // Check if current route needs desktop view
  const needsDesktopView = () => {
    const path = location.pathname;
    return (
      path.includes("/dashboard") ||
      path.includes("/interview") ||
      path.includes("/assessment")
    );
  };

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    if (code) {
      console.log("Found code in URL");
      console.log("State:", state);
      console.log("Code:", code);
      try {
        let res;
        if (state === "linkedin") {
          res = await axios.post(`/auth/linkedin`, { code });
        } else if (state === "github") {
          res = await axios.post(`/auth/github`, { code });
        } else {
          throw new Error("Unknown OAuth provider");
        }
        navigate("/dashboard");
      } catch (error) {
        console.log(`Found code in URL but could not login: ${error.message}`);
        alert(error.response.data);
      }
    }
  }, []);

  return (
    <div className="App">
      {!isLargeScreen && needsDesktopView() ? (
        <SmallScreenView />
      ) : (
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/auth/signin" element={<SignIn />} />
          <Route path="/auth/signup" element={<SignUp />} />
          <Route
            path="/interview"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Interview />
              </Suspense>
            }
          />
          <Route path="/assessment" element={<Assessment />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/interview" element={<Dashboard />} />
          <Route path="/dashboard/assessments" element={<Dashboard />} />
          <Route path="/dashboard/profile" element={<Dashboard />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
