import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Divider,
  Icon,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaEye, FaEyeSlash } from "react-icons/fa";
import Logo from "../../Components/Logo/Logo";
import "./SignIn.scss";
import useAnalytics from "../../analytics/useAnalytics";
import axios from "axios";
import { updateUser } from "../../ducks/slices/userSlice";
import { ChevronLeftIcon } from '@chakra-ui/icons';

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logEvent } = useAnalytics();

  const handleLogin = async () => {
    setErrors([]);
    if (!username || !password) {
      setErrors(["Please fill in all fields"]);
      return;
    }
    try {
      const res = await axios.post(`/auth/login`, { username, password });
      setPassword("");
      dispatch(
        updateUser({
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          githubId: res.data.github_id,
          linkedinId: res.data.linkedin_id,
        })
      );
      navigate("/dashboard");
      logEvent("Auth", "Login", "Success");
    } catch (err) {
      logEvent("Auth", "Login", "Failed");
      if (err.response && err.response.data) {
        setErrors(
          Array.isArray(err.response.data)
            ? err.response.data
            : [err.response.data]
        );
      } else {
        setErrors(["An error occurred. Please try again."]);
      }
    }
  };

  const handleGithubLogin = () => {
    logEvent("Auth", "Github Login", "Clicked");
    const state = "github";
    window.location.assign(
      `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&state=${state}`
    );
  };

  const handleLinkedinLogin = () => {
    logEvent("Auth", "LinkedIn Login", "Clicked");
    const state = "linkedin";
    window.location.assign(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
        process.env.REACT_APP_LINKEDIN_CLIENT_ID
      }&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_LINKEDIN_REDIRECT_URI
      )}&state=${state}&scope=openid%20profile%20email`
    );
  };

  return (
    <Box className="signin-page">
      <div className="signin-gradient-background" />
      <div className="signin-content">
        <Box
          className="signin-card"
          bg="rgba(24, 24, 27, 0.9)"
          backdropFilter="blur(10px)"
        >
          <VStack spacing={6} align="stretch">
            <Box position="absolute" top={6} left={6}>
              <Button
                leftIcon={<ChevronLeftIcon width={6} height={6} />}
                variant="ghost"
                color="gray.400"
                _hover={{ color: "white", bg: "transparent" }}
                onClick={() => navigate("/")}
                p={0}
              >
              </Button>
            </Box>

            <Box display="flex" justifyContent="center" mb={4}>
              <Logo width="40px" height="40px" />
            </Box>

            <Heading size="lg" textAlign="center" color="white">
              Welcome Back
            </Heading>

            {errors.length > 0 && (
              <VStack spacing={2}>
                {errors.map((error, index) => (
                  <Alert
                    key={index}
                    status="error"
                    borderRadius="md"
                    bg="red.900"
                    color="white"
                  >
                    <AlertIcon color="red.200" />
                    {error}
                  </Alert>
                ))}
              </VStack>
            )}

            <VStack spacing={4}>
              <Box width="100%">
                <Text color="gray.400" mb={2} fontSize="sm">
                  Username / Email
                </Text>
                <Input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  size="lg"
                  bg="gray.700"
                  border="none"
                  color="white"
                  _hover={{ bg: "gray.600" }}
                  _focus={{ bg: "gray.600", boxShadow: "none" }}
                />
              </Box>

              <Box width="100%">
                <Text color="gray.400" mb={2} fontSize="sm">
                  Password
                </Text>
                <InputGroup size="lg">
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    bg="gray.700"
                    border="none"
                    color="white"
                    _hover={{ bg: "gray.600" }}
                    _focus={{ bg: "gray.600", boxShadow: "none" }}
                  />
                  <InputRightElement width="3rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                      _active={{ bg: "transparent" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon
                        as={showPassword ? FaEyeSlash : FaEye}
                        color="gray.400"
                        _hover={{ color: "white" }}
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Box textAlign="right" mt={1}>
                  <Button
                    variant="link"
                    color="gray.400"
                    fontSize="sm"
                    onClick={() => navigate("/auth/forgot-password")}
                    _hover={{ color: "white" }}
                  >
                    Forgot Password?
                  </Button>
                </Box>
              </Box>
            </VStack>

            <Button
              bg="#673FD7"
              color="white"
              size="lg"
              _hover={{ bg: "#5a36bc" }}
              onClick={handleLogin}
              height="56px"
              fontSize="md"
            >
              Sign In
            </Button>

            <Divider borderColor="gray.600" />

            <Button
              leftIcon={<Icon as={FaGithub} />}
              bg="white"
              color="black"
              variant="solid"
              size="lg"
              _hover={{ bg: "gray.200" }}
              onClick={handleGithubLogin}
            >
              Continue with GitHub
            </Button>

            <Button
              leftIcon={<Icon as={FaLinkedin} />}
              bg="#0077B5"
              color="white"
              variant="solid"
              size="lg"
              _hover={{ bg: "#006699" }}
              onClick={handleLinkedinLogin}
            >
              Continue with LinkedIn
            </Button>

            <Text color="gray.400" fontSize="sm" textAlign="center">
              Don't have an account?{" "}
              <Button
                variant="link"
                color="#673FD7"
                onClick={() => navigate("/auth/signup")}
              >
                Sign Up
              </Button>
            </Text>
          </VStack>
        </Box>
      </div>
    </Box>
  );
};

export default SignIn;
